import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GlobalsProvider {

  public address = environment.address;
  public apiAddress = `${this.address}api/`;
  public panelAddress = `${this.apiAddress}planner/`;
  public app_id = 653751034774989;

  constructor(){};
  
}