import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalsProvider } from './globals';

export class CustomHttpParams extends HttpParams {
  constructor(public param1: boolean) {
   super();
  }
}

export class OverlayStart extends HttpParams {
  constructor(public param1: boolean) {
   super();
  }
}

@Injectable()
export class ServicesProvider {
  
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  };

  public address: string;
  private panelAddress: string;

  constructor(
    private http: HttpClient,
    private globals: GlobalsProvider
  ){
    this.address = globals.address;
    this.panelAddress = globals.panelAddress;
  };

  @Output() setMessage$: EventEmitter<any> = new EventEmitter();

  private dataToSendConvert(data: any, body, name = ''): void {
    for(let key in data) {
      if(data[key] instanceof Array || data[key] instanceof Object)
        this.dataToSendConvert(data[key], body, name.length ? `${name}[${key}]` : key);
      else
        body.append((name.length ? `${name}[${key}]` : key), data[key]);
    }
  }

  private dataToSend(data): string {
    let body = new URLSearchParams();
    this.dataToSendConvert(data, body);
    return body.toString();
  }
  
  public post = function(url, data) {
    return this.http.post(`${this.panelAddress}${url}`, this.dataToSend(data), this.httpOptions)
  }

  public postNoLoader = function(url, data) {
    return this.http.post(`${this.panelAddress}${url}`, this.dataToSend(data), {...this.httpOptions, ...{
      params: new CustomHttpParams(true)
    }})
  }

  public put = function(url, data) {
    return this.http.put(`${this.panelAddress}${url}`, this.dataToSend(data), this.httpOptions)
  }

  public putNoLoader = function(url, data) {
    return this.http.put(`${this.panelAddress}${url}`, this.dataToSend(data), {...this.httpOptions, ...{
      params: new CustomHttpParams(true)
    }})
  }

  public putNoEffect = function(url, data) {
    return this.http.put(`${this.panelAddress}${url}`, this.dataToSend(data), {...this.httpOptions, ...{
      params: new OverlayStart(true)
    }})
  }

  public delete(url) {
    return this.http.delete(`${this.panelAddress}${url}`)
  }

  public get(url, changeApi = null): Observable<any> {
    return this.http.get(`${changeApi ? (this.address + changeApi) : this.panelAddress}${url}`);
  }

  public getLocal(url): Observable<any> {
    return this.http.get(url, {
      params: new CustomHttpParams(true)
    });
  }

  public getNoLoader(url): Observable<any> {
    return this.http.get(`${this.panelAddress}${url}`, {
      params: new CustomHttpParams(true)
    });
  }

  public getOverlayStart(url):Observable<any> {
    return this.http.get(`${this.panelAddress}${url}`, {
      params: new OverlayStart(true)
    });
  }
}