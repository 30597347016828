import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController
  ) {
  }

  async showAlert(text, sub = '', method = '') {
    let alert = await this.alertCtrl.create({
      header: text,
      message: sub ? sub : '',
      cssClass: 'custom-alert ' + (method ? `alert-${method}` : 'alert-success'),
      backdropDismiss: false,
      buttons: [{
        text: 'Zamknij',
        role: 'cancel',
        cssClass: method ? `button-${method}` : 'button-success'
      }],
    });
    await alert.present();
  }

}
