import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Injectable()

export class MenuService {

  constructor(
    private menu: MenuController
  ) {};

  public toggleMenu(menuIndex: number): void {
    this.menu.getMenus().then((data) => {
      if(menuIndex > 0 || window.innerWidth < 992) {
        data[menuIndex].open ? data[menuIndex].open() : data[menuIndex].close();
      } else if(menuIndex <= 0) {
        data[menuIndex].classList.contains('slide') ? data[menuIndex].classList.remove('slide') : data[menuIndex].classList.add('slide');
      }
    });
  }

}