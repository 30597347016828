import { Component } from '@angular/core';
import { MenuService } from '../../providers/menu';
import { GlobalsProvider } from 'src/app/providers/globals';
import { ServicesProvider } from 'src/app/providers/service';

@Component({
  selector: 'right-menu-component',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss']
})

export class RightMenuComponent {

  constructor(
    private menuService: MenuService,
    public globals: GlobalsProvider,
    private service: ServicesProvider,
  ) {
  }

  public toggleMenu(index: number): void {
    this.menuService.toggleMenu(index);
  }

  public logOut(): void {
    this.service.post('logout', {}).subscribe(() => {
      window.location.href = this.globals.address;
    });
  }
}
