import { Injectable } from '@angular/core';
import { ServicesProvider } from '../service';
import { StorageProvider } from '../storage-service';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable()
export class HarmonogramPageProvider {

  private hasSaved = null;

  public schedules = [];

  private firstLoad = false;

  constructor(
    public service: ServicesProvider,
    private storageProvider: StorageProvider,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.storageProvider.getFromStorage('hasSaved').then((value: any) => {
        this.hasSaved = value;
      });
    });
  }

  public getData(): any {
    return this.schedules;
  }

  public async getQuestion(questionId: number): Promise<any> {
    return await this.service.get(`schedulequestions/${questionId}`).toPromise();
  }

  async loadData(): Promise<any> {
    return this.service.get('schedulemodules/').toPromise().then((res: any) => {
      if(!this.firstLoad)
        this.firstLoad = true;
      this.schedules = res;
      return res;
    });
  }

  public getListAsPDF(): Observable<any> {
    return this.service.get('schedulemodules?pdf=pdf');
  }

  public async setOpen(): Promise<any> {
    return this.saveDataNoLoader().then();
  }

  async addStage(stage): Promise<void> {
    await this.checkedHasSaved();
    this.schedules.push(stage);
    return this.saveData().then(() => {
      this.loadData();
    });
  }

  async updateStage(stage): Promise<any> {
    const stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === stage.plannerScheduleModuleId);
    await this.checkedHasSaved();
    this.schedules[stageIdx].name = stage.name;
    return this.saveData();
  }

  async addTask(task, retry: boolean = false): Promise<any> {
    let stageIdx;
    if(!retry)
      stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.scheduleModuleId);
    await this.checkedHasSaved();
    if(!retry)
      this.schedules[stageIdx].elements.push(task);
    return this.saveData().then((res: any) => {
      this.loadData();
      return res;
    });
  }

  public async editTask(task): Promise<any> {
    if((typeof task.newScheduleModuleId !== 'undefined') && (task.scheduleModuleId !== task.newScheduleModuleId)) {
      const stageIdx =  this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.newScheduleModuleId);
      const elementIdx =  this.schedules[stageIdx].elements.findIndex((x: any) => x.plannerScheduleId === task.plannerScheduleId);
      await this.checkedHasSaved();
      this.schedules[stageIdx].elements.splice(elementIdx, 1);
      this.schedules[this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.scheduleModuleId)].elements.push(task);
    } else {
      const stageIdx =  this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.scheduleModuleId);
      const elementIdx =  this.schedules[stageIdx].elements.findIndex((x: any) => x.plannerScheduleId === task.plannerScheduleId);
      await this.checkedHasSaved();
      this.schedules[stageIdx].elements[elementIdx] = task;
    }
    return this.saveData().then((res: any) => {
      this.loadData();
      return res;
    });
  }

  async updateTask(task): Promise<any> {
    const stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.scheduleModuleId);
    const taskIdx = this.schedules[stageIdx].elements.findIndex((x: any) => x.plannerScheduleId === task.plannerScheduleId);
    await this.checkedHasSaved();
    this.schedules[stageIdx].elements[taskIdx] = task;
    return this.saveData();
  }

  async deleteStage(stage): Promise<any> {
    const stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === stage.plannerScheduleModuleId);
    await this.checkedHasSaved();
    this.schedules.splice(stageIdx, 1);
    return this.saveData();
  }

  async deleteTask(task): Promise<any> {
    const stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === task.scheduleModuleId);
    const taskIdx = this.schedules[stageIdx].elements.findIndex((x: any) => x.plannerScheduleId === task.plannerScheduleId);
    await this.checkedHasSaved();
    this.schedules[stageIdx].elements.splice(taskIdx, 1);
    return this.saveData();
  }

  async reorderSchedule(idxFrom: number, idxTo: number): Promise<any> {
    const newPosition = this.schedules[idxTo].position;
    await this.checkedHasSaved();
    for (let i = idxTo; i < idxFrom; i++)
      this.schedules[i].position++;
    this.schedules[idxFrom].position = newPosition;
    const stage = this.schedules[idxFrom];
    this.schedules.splice(idxFrom, 1);
    this.schedules.splice(idxTo, 0, stage);
    return this.saveData();
  }

  async newReorderSchedule(schedules): Promise<any> {
    this.schedules = schedules;
    await this.checkedHasSaved();
    return await this.saveData().then(() => {
      this.loadData();
    });
  }

  async reorderTask(idxFrom: number, idxTo: number, stageId: number): Promise<any> {
    const stage = this.getStageById(stageId);
    await this.checkedHasSaved();
    if(idxTo >= stage.elements.length)
      idxTo = stage.elements.length - 1;
    const newPosition = stage.elements[idxTo].position;
    for(let i = idxTo; i < idxFrom; i++)
      stage.elements[i].position++;
    stage.elements[idxFrom].position = newPosition;
    const task = stage.elements[idxFrom];
    stage.elements.splice(idxFrom, 1);
    stage.elements.splice(idxTo, 0, task);
    return this.saveData();
  }

  async newReorderTask(schedules): Promise<any> {
    this.schedules = schedules;
    await this.checkedHasSaved();
    return await this.saveData().then(() => {
      this.loadData();
    });
  }

  async saveData(): Promise<any> {
    await this.checkedHasSaved();
    return this.service.post('schedulemodules/', { schedule: this.schedules }).toPromise();
  }

  async saveDataNoLoader(): Promise<any> {
    await this.checkedHasSaved();
    return this.service.postNoLoader('schedulemodules/', { schedule: this.schedules }).toPromise();
  }

  public async checkedHasSaved(): Promise<any> {
    if(!this.hasSaved) {
      await this.service.post('schedulemodules/', { schedule: this.schedules }).toPromise();
      await this.loadData();
      await (this.hasSaved = 'true');
      await this.storageProvider.setToStorage('hasSaved', 'true');
    }
  }

  public getStageById(stageId: number): any {
    const stageIdx = this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === stageId);
    return this.schedules[stageIdx];
  }

  public getStageIndexByTaskId(taskId: number): number | string {
    if(this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === taskId) > -1)
      return this.schedules.findIndex((x: any) => x.plannerScheduleModuleId === taskId);
    return 0;
  }

  public getQuestionsPdfLink(questionId: number): string {
    return `${this.service.address}/pdf/schedulequestions/${questionId}`;
  }

  public async resetData(): Promise<any> {
    await this.service.post('schedulemodules', {}).toPromise();
    await this.loadData().then((data: any) => {
      this.storageProvider.removeFromStorage('hasSaved');
      this.hasSaved = null;
      this.schedules = data;
    });
  }
}
