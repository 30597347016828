import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { MenuService } from '../../providers/menu';
import { RightMenuComponent } from './right-menu.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  providers: [
    MenuService
  ],
  declarations: [
    RightMenuComponent
  ],
  exports: [
    RightMenuComponent
  ]
})
export class RightMenuComponentModule {}
