import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesProvider } from './service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = {
    id: null,
    user_id: null,
    prepaid: 0,
    consultant: {
      name: '',
      name2: '',
      mail: '',
      phone: ''
    },
    date: ''
  };
  stopCondition = true;

  @Output() setUser$: EventEmitter<any> = new EventEmitter();

  constructor(
    public http: HttpClient,
    private service: ServicesProvider
  ) {
  }

  public startUser(): void {
    this.service.getNoLoader('login').subscribe(data => {
      this.setUser$.emit(data);
      this.user = data;
      this.refreshUser();
    });
  }

  public getUser(): void {
    if(typeof this.user !== 'undefined')
      this.setUser$.emit(this.user);
    return this.startUser();
  }

  public deleteUser(): void {
    this.setUser$.emit({});
  }

  private refreshUser(): void {
    interval(60000).pipe(takeWhile(() => this.stopCondition))
    .subscribe(() => {
      this.service.getNoLoader('login').subscribe((data: any) => {
        this.setUser$.emit(data);
        this.user = data;
      });
    });
  }
}
