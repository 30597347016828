
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageProvider {
  constructor(private nativeStorage: NativeStorage) { }

  public setToStorage(name, value): Promise<any> {
    return this.nativeStorage.setItem(name, value);
  }

  public getFromStorage(name): Promise<any> {
    return this.nativeStorage.getItem(name);
  }

  public removeFromStorage(name): Promise<any> {
    return this.nativeStorage.remove(name);
  }
}

