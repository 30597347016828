import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { httpInterceptor } from './providers/https-interceptor';
import { ServicesProvider } from './providers/service';
import { RightMenuComponentModule } from './components/right-menu/right-menu.component.module';
import { IonicSwipeAllModule } from 'ionic-swipe-all';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HarmonogramPageProvider } from './providers/harmonogram/harmonogram.page.service';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';
import { StorageProvider } from './providers/storage-service';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ClosetPageProvider } from './providers/closet/closet.page.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';


declare var Hammer: any;

export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel: 'Anuluj';
  setBtnLabel: 'Wybierz';
}

export class MyHammerConfig extends HammerGestureConfig  {
  buildHammer(element: HTMLElement): any {
    const mc = new Hammer(element, {
      touchAction: 'auto',
    });
    return mc;
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    RightMenuComponentModule,
    IonicSwipeAllModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    NativeStorage,
    ServicesProvider,
    StorageProvider,
    HarmonogramPageProvider,
    ClosetPageProvider,
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'pl'
    },
    {
      provide: OwlDateTimeIntl,
      useClass: DefaultIntl
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    Geolocation
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
