import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { CustomHttpParams, OverlayStart } from './service';
import { LoadingController } from '@ionic/angular';
import { AlertService } from './alert-service';
import { UserService } from './user-service';
import { GlobalsProvider } from './globals';

@Injectable()
export class httpInterceptor implements HttpInterceptor {

  token = null;
  body: any;
  i = 0;
  item: any;
  loader;

  constructor(
    private loadingCtrl: LoadingController,
    private alertService: AlertService,
    private userService: UserService,
    private globals: GlobalsProvider
  ) {
    this.body = document.getElementsByTagName('body')[0];
  }


  async showLoading() {
    this.loader = await this.loadingCtrl.create({
      spinner: 'circles',

    });
    await this.loader.present().then(() => setTimeout(() => {
      if(this.i <= 0) {
        this.loader.dismiss();
        document.getElementsByTagName('ion-app')[0].classList.remove('onLoading');
      }
    }, 1000));
  }

  private navigateToHome(): void {
    setTimeout(() => {
      window.location.href = this.globals.address;
    }, 2000);
  }

  errorMethod(message, err, method): void {
    if (err.error instanceof Error) {
      console.log('An error occurred:', err.error.message);
    } else {
      let ret = '';
      if(err.error !== undefined) {
        for(const error in err.error)
          for(const key in err.error[error])
            ret += `${err.error[error][key]}<br>`;
        this.alertService.showAlert(message, ret, 'danger');
      } else
        this.alertService.showAlert(message, '', 'danger');
    }
  }

  howLongLoader(): void {
    setTimeout(() => {
      --this.i;
      if(this.i <= 0 && this.loader != null) {
        this.loader.dismiss();
        document.getElementsByTagName('ion-app')[0].classList.remove('onLoading');
        this.i = 0;
      }
    }, 300);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!(req.params instanceof CustomHttpParams && req.params.param1)) {
      ++this.i;
      if(this.i === 1)
        this.showLoading();
    }
    if(req.params instanceof OverlayStart && req.params.param1) {
      document.getElementsByTagName('ion-app')[0].classList.add('onLoading');
    }
    const request = req.clone();


    return next.handle(request).pipe(
    tap((event: any) => {
      if(event instanceof HttpResponse && !((req.params instanceof CustomHttpParams && req.params.param1) || (req.params instanceof OverlayStart)))
        this.howLongLoader();
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if(!(req.params instanceof CustomHttpParams && req.params.param1))
          this.howLongLoader();
        if(req.params instanceof OverlayStart)
          return;
        if(err.status === 401) {
          this.errorMethod('Musisz się zalogować', err, 'danger');
          this.navigateToHome();
          this.userService.setUser$.emit({username: '', userId: null});
        } else if(err.status === 403) {
          this.errorMethod('Musisz się zalogować', err, 'danger');
          this.navigateToHome();
        }  else if(err.status === 404) {
          this.errorMethod('Nie znaleziono strony', err, 'danger');
          this.navigateToHome();
        } else if(err.status === 500) {
          this.errorMethod('Błąd serwera. Spróbuj ponownie później', err, 'danger');
          this.navigateToHome();
        } else if(err.status === 422) {
          this.errorMethod('Wypełnij formularz poprawnie', err, 'danger');
        }
      }
    })
  );
  }
}
