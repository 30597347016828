import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// 
const routes: Routes = [
  {
    path: 'ulubione-firmy',
    data: { title: 'Ulubione firmy' },
    loadChildren: './pages/closet/closet.module#ClosetPageModule'
  },
  {
    path: 'rejestracja',
    loadChildren: './pages/registration/registration.module#RegistrationPageModule',
    data: { title: 'Rejestracja - Wesele z klasą', alone: true }
  },
  {
    path: 'potwierdzenie-konta',
    loadChildren: './pages/confirmation-account/confirmation-account.module#ConfirmationAccountPageModule',
    data: { title: 'Potwierdzenie konta - Wesele z klasą', alone: true }
  },
  {
    path: 'inspiracje',
    loadChildren: './pages/inspirations/inspirations.module#InspirationsPageModule',
    data: { title: 'Moje inspiracje', alone: false }
  },
  {
    path: 'harmonogram',
    loadChildren: './pages/harmonogram/harmonogram.module#HarmonogramPageModule',
    data: { title: 'Moja lista zadań', alone: false }
  },
  {
    path: 'lista-gosci',
    data: { title: 'Moja lista gości' },
    loadChildren: './pages/guestslist/guestslist.module#GuestslistPageModule'
  },
  {
    path: 'plan-stolow',
    data: { title: 'Moja lista stołów' },
    loadChildren: './pages/tables/tables.module#TablesPageModule'
  },
  {
    path: '**',
    redirectTo: 'inspiracje',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }