import { Component, ViewChild } from '@angular/core';

import { MenuController, Platform, IonContent } from '@ionic/angular';
import { GlobalsProvider } from './providers/globals';
import { UserService } from './providers/user-service';
import { Router, NavigationEnd, ActivationEnd, NavigationStart, RouteConfigLoadStart } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  @ViewChild('menu') menu;
  public registerSite = true;

  private firstLoad = true;

  public activeElementUrl = '';

  public activeMenuItem = -1;

  public appPages = [
    {
      title: 'Wiadomości',
      href: `${this.globals.address}planner/odpowiedzi`,
      icon: 'messages'
    },
    {
      title: 'Ulubione firmy',
      url: '/ulubione-firmy',
      icon: 'ulubione_firmy'
    },
    {
      title: 'Lista zadań',
      url: '/harmonogram',
      icon: 'guests_list'
    },
    {
      title: 'Lista gości',
      url: '/lista-gosci',
      icon: 'goscie'
    },
    // {
    //   title: 'Wybrane firmy',
    //   url: '/list',
    //   icon: 'company'
    // },
    {
      title: 'Plan stołów',
      url: '/plan-stolow',
      icon: 'table_layout'
    },
    // {
    //   title: 'Nasza strona',
    //   url: '/list',
    //   icon: 'our-site'
    // },
    {
      title: 'Inspiracje',
      url: '/inspiracje',
      icon: 'heart'
    },
    {
      title: 'Budżet',
      href: `${this.globals.address}planner/budzet`,
      icon: 'budget'
    }
    // {
    //   title: 'Dokumenty',
    //   url: '/list',
    //   icon: 'documents'
    // }
  ];

  constructor(
    public globals: GlobalsProvider,
    private userService: UserService,
    private router: Router,
    private menuController: MenuController,
    private title: Title
  ) {
    this.userService.setUser$.subscribe((data: any) => {
      if(data.type === 'io')
        window.location.href = this.globals.address + 'planner/odpowiedzi';
    });
    this.router.events.subscribe((event: any) => {
      if(event instanceof ActivationEnd)
        if(this.registerSite)
          this.title.setTitle(event.snapshot.data.title);
        else {
          this.title.setTitle(`(${'0'}) ${event.snapshot.data.title} - Wesele z klasą`);
          if (this.firstLoad) {
            this.firstLoad = false;
            this.userService.startUser();
          }
        }
      if(event instanceof RouteConfigLoadStart)
        this.registerSite = event.route.data.alone;
      if(event instanceof NavigationEnd)
        this.activeElementUrl = event.url;
    });
  }

  public setActiveMenuItem(i: number, $event): void {
    this.activeMenuItem = i;
    this.menu.el.classList.remove('slide');
    if(this.appPages[i].href)
      window.open(this.appPages[i].href, (($event.which === 2 ? '_blank' : '_self')));
  }

  public toggleMenu(menuIndex: number): void {
    this.menuController.getMenus().then((data: any) => {
      if(menuIndex > 0 || window.innerWidth < 992)
        data[menuIndex].isOpen().then((dataOpen: any) => {
          dataOpen ? data[menuIndex].close() : data[menuIndex].open();
        });
      else if(menuIndex <= 0)
        data[menuIndex].classList.contains('slide') ? data[menuIndex].classList.remove('slide') : data[menuIndex].classList.add('slide');
    });
  }

  public scrollToTop(): void {
    ((document.querySelector('ion-content[upReturn]') as unknown) as IonContent).scrollToTop(500);
  }
}
