import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServicesProvider } from '../service';
import { StorageProvider } from '../storage-service';
import { ClosetEditCategory } from 'src/app/interfaces/closet/closet-edit-category/closet-Edit-Category';
import { ClosetCategory } from 'src/app/interfaces/closet/closet-category/closet-category';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ClosetPageProvider {
  public categories = [];

  public heart = {
    categoryQuantity: 0,
    minCategory: 0,
    percent: '0',
    classes: 'none'
  };

  public mainInfo = {
    categoriesQuantity: 0,
    minQuantity: 0,
    noticesQuantity: 0
  };

  private hasSavedCats = null;

  constructor(
    public service: ServicesProvider,
    private storageProvider: StorageProvider,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.storageProvider.getFromStorage('hasSavedCats').then((value: any) => {
        this.hasSavedCats = value;
      });
    });
  }

  public getData(): Array<any> {
    return this.categories;
  }

  public async loadData(): Promise<any> {
    return this.service.get('categories').toPromise().then((res: any) => {
      this.categories = res;
      this.setWavesValues();
      return res;
    });
  }

  public setWavesValues(): void {
    this.heart.categoryQuantity = this.categories.filter((cat: any) => cat.active).length;
    this.heart.minCategory = 0;
    this.mainInfo.minQuantity = 0;
    this.mainInfo.noticesQuantity = 0;
    this.mainInfo.categoriesQuantity = 0;
    this.categories.forEach((category: any) => {
      if(category.active) {
        ++this.mainInfo.categoriesQuantity;
        if(category.mines)
          this.heart.minCategory++;
      }
      if(category.mines)
        this.mainInfo.minQuantity += category.mines;
      if(category.notices)
        this.mainInfo.noticesQuantity += category.notices;
    });
    this.changeVawe();
  }

  private changeVawe(): void {
    const vawes = document.getElementsByClassName('wave');
    if(!this.heart.categoryQuantity) {
      this.heart.percent = String(0);
      this.heart.classes = 'full';
      return;
    }
    const percentWave = ((this.heart.minCategory / this.heart.categoryQuantity) * 100);
    this.heart.percent = ((this.heart.minCategory / this.heart.categoryQuantity) * 100).toFixed(0);
    for(let i = 0; i < vawes.length; i++)
      if(percentWave === 100)
        this.heart.classes = 'full';
      else if(percentWave === 0)
        this.heart.classes = 'none';
      else {
        this.heart.classes = '';
        vawes[i]['style'].top = `calc(${100 - percentWave}% - 20px)`;
      }
  }

  async newReorderCategories(categoriesCopy): Promise<any> {
    this.categories = categoriesCopy;
    await this.checkedHasSaved();
    return await this.saveData().then(() => {
      this.loadData();
    });
  }

  public async checkedHasSaved(): Promise<any> {
    if(!this.hasSavedCats) {
      await this.service.post('categories', { categories: this.categories }).toPromise();
      await this.loadData();
      await (this.hasSavedCats = 'true');
      await this.storageProvider.setToStorage('hasSavedCats', 'true');
    }
  }

  async saveData(): Promise<any> {
    await this.checkedHasSaved();
    return this.service.post('categories', { categories: this.categories }).toPromise();
  }

  public getCategoryById(id: string): Observable<any> {
    return this.service.get(`categories/${id}`);
  }

  public getMineCategoryById(id: string): Observable<any> {
    return this.service.get(`categories/${id}/mines`);
  }

  public editMineCategory(mineCategory: ClosetCategory, name: string, edit: ClosetEditCategory ): Observable<any> {
    const mineCategoryCopy = JSON.parse(JSON.stringify(mineCategory));
    mineCategoryCopy[name] = edit[name];
    return this.service.put(`mines/${mineCategory.mineId}`, mineCategoryCopy);
  }

  public getphoneMineCategory(id: number): Observable<any> {
    return this.service.get(`mines/${id}/phones`);
  }

  public removeCategories(id: number): Observable<any> {
    return this.service.delete(`mines/${id}`);
  }


}
